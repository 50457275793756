import clsx from 'clsx';

export const Arrow = ({
  variant,
  className,
}: {
  variant: 'primary' | 'secondary' | 'green' | 'candidate-green';
  className?: string;
}) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`min-w-[18px] ${className ? className : ''}`}
    >
      <path
        d="M6.51285 2.99494L12.2561 2.99477L16.0851 9.62716L10.341 9.62686L6.51285 2.99494Z"
        className={clsx('transition ease-out duration-700 ', {
          ['fill-[#47546780] group-hover:fill-purple-7']: variant === 'primary',
          ['fill-[#f9fafb80] group-hover:fill-[#F9FAFB]']: variant === 'secondary',
          ['fill-[#878B93] group-hover:fill-green-7']: variant === 'green',
          ['fill-green-8 group-hover:fill-green-7']: variant === 'candidate-green',
        })}
      />
      <path
        d="M6.51213 16.2587L12.2562 16.259L16.085 9.6272L10.341 9.6269L6.51213 16.2587Z"
        className={clsx('transition ease-out duration-700 ', {
          ['fill-[#0c111d80] group-hover:fill-purple-9']: variant === 'primary',
          ['fill-[#ffffff80] group-hover:fill-[#F9FAFB]']: variant === 'secondary',
          ['fill-[#e6e7e8] group-hover:fill-green-9']: variant === 'green',
          ['fill-green-9 group-hover:fill-green-8']: variant === 'candidate-green',
        })}
      />
    </svg>
  );
};
