import clsx from 'clsx';
import { TConicGradientClasses } from '@/interfaces/layout';

interface IProps {
  classname?: string;
  gradient?: TConicGradientClasses;
}

export const ConicBackground = ({ classname, gradient = 'bg-conic-gradient-purple' }: IProps) => {
  return (
    <div
      className={clsx(
        `
          w-[150%] h-[150%] 
          absolute top-1/2 left-1/2 
          transform translate-x-[-50%] translate-y-[-50%]
          transition-transform ease-out duration-700
          group-hover:rotate-180 ${gradient} 
        `,
        {
          [`${classname}`]: classname,
        },
      )}
    />
  );
};
