'use client';
import { ArrowButton } from '@/components/Links/ArrowButton';
import { useRouter } from 'next/navigation';
import { routeNames } from '@/constants/routes';

export const CTAbuttons = () => {
  const router = useRouter();

  return (
    <div className="flex gap-x-[8px] z-[1]">
      <ArrowButton
        testId="404-button-back"
        buttonClassname="text-black border-white50"
        gradient="bg-conic-gradient-light"
        arrowVariant="secondary"
        gradientPosition="h-[470%]"
        withArrow={false}
        onClick={() => router.back()}
      >
        Go Back
      </ArrowButton>
      <ArrowButton
        gradient="bg-conic-gradient-purple"
        buttonClassname="text-white border-purple-5"
        arrowVariant="secondary"
        gradientPosition="h-[470%]"
        onClick={() => router.replace(routeNames.home)}
      >
        Go home
      </ArrowButton>
    </div>
  );
};
